/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useContext, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MenuItem, Select, FormControl, InputLabel, InputBase, fabClasses } from "@mui/material";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import LinearProgress from "@mui/material/LinearProgress";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { ProjectContext } from "../../context";
import projectsTableData from "layouts/1doptimizer/data/projectsTableData";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridCellModes,
  useGridApiRef,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import "./modalStyles.css";

const Tables = () => {
  const { projects } = useContext(ProjectContext);
  const [selectedJob, setSelectedJob] = useState({ key: 1, label: "Select Job" });
  const [Joboptions, setJoboptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hash, setHash] = useState(window.location.hash.substring(1));
  const [selectedCellParams, setSelectedCellParams] = useState(null);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [Rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleJobChange = (event, newValue) => {
    setSelectedJob(newValue);
    if (newValue) {
      window.location.hash = newValue.key;
    }
  };
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const filter = createFilterOptions();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { columns, rows } = await projectsTableData({ selectedJob, openModal, closeModal });
        setTableData({ columns, rows });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (selectedJob) {
      fetchData();
      localStorage.setItem("selectedJob", selectedJob.key);
    }
  }, [selectedJob, hash]);

  useEffect(() => {
    var hashr = window.location.hash.substring(1);
    const storedKey = localStorage.getItem("selectedJob");
    if (hashr == "" && storedKey) {
      hashr = storedKey;
    }

    const Joboptions2 = Object.entries(projects || {})
      .map((project) => ({
        key: project[1].ID,
        label: project[1].JobName,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setJoboptions(Joboptions2);
    if (projects) {
      const Joboption = Object.entries(projects || {})
        .map((project) => ({
          key: project[1].ID,
          label: project[1].JobName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      const matchingJob = Joboption?.find((job) => job.key === hashr);
      if (matchingJob) {
        setSelectedJob(matchingJob);
      }
    }
  }, [projects]);

  useEffect(() => {
    if (Object.keys(tableData.rows).length > 0) {
      const initialRows = Object.entries(tableData.rows)
        .map((Cutlist, index) => {
          const { Finish, Die, Description, yieldr, Order, StockLength, status, id, key } =
            Cutlist[1];
          return {
            Finish,
            Die,
            Description,
            yieldr,
            Order,
            StockLength,
            status,
            id,
            key,
          };
        }) // Sort the rows by Status first, then by JobName
        .sort((a, b) => {
          // Compare by Status first
          if (a.Description < b.Description) {
            return -1; // a comes before b
          } else if (a.Description > b.Description) {
            return 1; // a comes after b
          } else {
            // If Status values are equal, compare by JobName
            // return a.Finish.localeCompare(b.Finish);
          }
        });

      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);
  const apiRef = useGridApiRef();
  const norow = ({}) => <div>no</div>;
  const ImageModal = ({ imageUrl, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <img src={imageUrl} width="500" alt="Modal" />
        </div>
      </div>
    );
  };
  const Editrow = ({}) => (
    <Card>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8} md={5} lg={2}>
            <Button
              color="primary"
              startIcon={<Icon>print</Icon>}
              onClick={() => {
                //exportLabels();
              }}
            >
              New CutList
            </Button>
          </Grid>
          <Grid item xs={8} md={5} lg={2}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                p: 1,
              }}
            >
              <GridToolbarExport csvOptions={{ fileName: `${selectedJob.label} Cutlists` }} />
            </Box>
          </Grid>
          <Grid item xs={8} md={5} lg={3}>
            <Autocomplete
              disablePortal
              id="Select-Project"
              disableClearable
              freeSolo
              options={Joboptions}
              getOptionLabel={(option) => option.label}
              value={selectedJob}
              onChange={handleJobChange}
              onLoad={handleJobChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const existingOption = options.find((option) => option.label === params.inputValue);

                if (params.inputValue && !existingOption) {
                  filtered.push({
                    id: params.inputValue,
                    label: `+Add ${params.inputValue}`,
                    key: params.inputValue,
                  });
                }

                return filtered;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Select Project"
                    InputLabelProps={{
                      shrink: true, // Control label behavior
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={8} md={5} lg={3}>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Cut Lists
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <DataGrid
                      rows={Rows}
                      columns={tableData.columns}
                      disableRowSelectionOnClick
                      disableColumnReorder={true}
                      sortingOrder={["asc", "desc"]}
                      disableColumnMenu={true}
                      disableEval
                      apiRef={apiRef}
                      editMode="cell"
                      loading={Rows.length == 0}
                      slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: Editrow,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          selectedCellParams,
                          setSelectedCellParams,
                        },
                      }}
                    />
                  </div>
                )}
              </MDBox>
              <MDBox>
                {modalOpen && <ImageModal imageUrl={selectedImage} onClose={closeModal} />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
