import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/useAutocomplete";
import { TextField } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Modal, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function UserEditModal({ UserID }) {
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = useState(null);
  const [ID, setID] = useState(0);
  const [Shop, setShop] = useState(false);
  const [JobAdmin, setJobAdmin] = useState(false);
  const [VenMod, setVendorModule] = useState(false);
  const [JobsModule, setJobsModule] = useState(false);
  const [POModule, setPOModule] = useState(false);
  const [Proposals_Mod, setProposals_Mod] = useState(false);
  const [SeniorField, setSeniorField] = useState(false);
  const [ShopAdmin, setShopAdmin] = useState(false);
  const [TimeAndEquipMod, setTimeAndEquipMod] = useState(false);
  const [EmployeeAdmin, setEmployeeAdmin] = useState(false);
  const [FieldHours, setFieldHours] = useState(false);
  const [Timesheet, setTimesheet] = useState(false);
  const [Active, setActive] = useState(false);
  const [UserName, setUserName] = useState("");
  const [Title, setTitle] = useState("");
  const [Initials, setInitials] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Manager_ID, setManager_ID] = useState("");
  const [Hire_Date, setHire_Date] = useState("2024-01-01");
  const [TrackingID, setTrackingID] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function toggleActiveState() {
    setActive((prevActive) => !prevActive);
  }
  const handleSave = async () => {
    const url = "https://app.waltekltd.org/Forms/Update_Employee.php"; // Replace with your actual save URL
    const postData = {
      UserID,
      Shop,
      JobAdmin,
      VenMod,
      JobsModule,
      POModule,
      Proposals_Mod,
      SeniorField,
      ShopAdmin,
      TimeAndEquipMod,
      EmployeeAdmin,
      FieldHours,
      Timesheet,
      Active,
      UserName,
      Title,
      Initials,
      Phone,
      Email,
      Manager_ID,
      Hire_Date,
      TrackingID,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      // Optionally handle successful save (e.g., show a success message)
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
      const postData = new URLSearchParams();
      postData.append("id", UserID);
      postData.append("table", "employeerecords");
      postData.append("idname", "id");
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: postData,
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setUserData(data);
        setID(data[1].ID);
        setShop(!!parseInt(data[1].Shop));
        setVendorModule(!!parseInt(data[1].VendorModule));
        setJobAdmin(!!parseInt(data[1].JobAdmin));
        setJobsModule(!!parseInt(data[1].JobsModule));
        setPOModule(!!parseInt(data[1].POModule));
        setProposals_Mod(!!parseInt(data[1].Proposals_Mod));
        setSeniorField(!!parseInt(data[1].SeniorField));
        setShopAdmin(!!parseInt(data[1].ShopAdmin));
        setTimeAndEquipMod(!!parseInt(data[1].TimeAndEquipMod));
        setFieldHours(!!parseInt(data[1].FieldHours));
        setTimesheet(!!parseInt(data[1].Timesheet));
        setActive(!!parseInt(data[1].Active));
        setEmployeeAdmin(!!parseInt(data[1].EmployeeAdmin));
        setUserName(data[1].EmployeeU);
        setTitle(data[1].Title);
        setInitials(data[1].Initials);
        setPhone(data[1].Phone);
        setEmail(data[1].Email);
        setTrackingID(data[1].TrackingID);
        setHire_Date(JSON.parse(data[1].User_Settings).Hire_Date);
        setManager_ID(data[1].Manager_ID);
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    fetchData();
  }, [UserID]);
  return (
    <Container>
      {userData && (
        <Card
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
          sx={{ boxShadow: "none" }}
        >
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {UserName}
            </MDTypography>
            <MDBox mb={2}>
              <MDInput
                value={UserName}
                type="text"
                label="Full Name"
                onChange={(event) => setUserName(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Title}
                type="text"
                label="Job Title"
                onChange={(event) => setTitle(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Phone}
                type="tel"
                label="Phone"
                onChange={(event) => setPhone(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Email}
                type="email"
                label="Email"
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Manager_ID}
                type="text"
                label="Manager_ID"
                onChange={(event) => setManager_ID(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Hire_Date}
                type="date"
                label="Hire Date"
                onChange={(event) => setHire_Date(event.target.value)}
                fullWidth
                placeholder="1"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={Initials}
                type="text"
                label="Initials"
                onChange={(event) => setInitials(event.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={TrackingID}
                type="number"
                label="Employee ID"
                onChange={(event) => setTrackingID(event.target.value)}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
            >
              Settings
            </MDTypography>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={Shop} onChange={() => setShop(!Shop)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Shop Employee
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={EmployeeAdmin} onChange={() => setEmployeeAdmin(!EmployeeAdmin)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Employee Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={VenMod} onChange={() => setVendorModule(!VenMod)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Vendor Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={JobAdmin} onChange={() => setJobAdmin(!JobAdmin)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Job Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={JobsModule} onChange={() => setJobsModule(!JobsModule)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Jobs Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={POModule} onChange={() => setPOModule(!POModule)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Purchase Order Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={Proposals_Mod} onChange={() => setProposals_Mod(!Proposals_Mod)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Proposals Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={SeniorField} onChange={() => setSeniorField(!SeniorField)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Field Supervisor
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={ShopAdmin} onChange={() => setShopAdmin(!ShopAdmin)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Shop Administrator
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  checked={TimeAndEquipMod}
                  onChange={() => setTimeAndEquipMod(!TimeAndEquipMod)}
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Purpose Unsure
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={FieldHours} onChange={() => setFieldHours(!FieldHours)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Field Hours Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={Timesheet} onChange={() => setTimesheet(!Timesheet)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Timesheet Module Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={Active} onChange={toggleActiveState} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Active
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDButton onClick={handleSave} variant="contained" color="primary">
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </Container>
  );
}

UserEditModal.defaultProps = { UserID: true };
UserEditModal.propTypes = { UserID: PropTypes.string.isRequired };
export default UserEditModal;
