import React, { useState, useEffect, useContext } from "react";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDTypography from "components/MDTypography";
import { ProjectContext } from "../../context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/Project-Details/components/Header";

// Images

function Overview() {
  const [CurrentProject, setCurrentProject] = useState({});
  const [loading, setLoading] = useState(true);
  const [first, setfirst] = useState(true);
  const { projects } = useContext(ProjectContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const hash = window.location.hash.substring(1);
        if (projects) {
          const Joboptions = Object.entries(projects).find((project) => project[1].ID == hash);

          if (Joboptions) {
            setCurrentProject(Joboptions[1]);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
        setLoading(false);
      }
    };
    if (!first) {
      fetchData();
    }
  }, [projects]);
  useEffect(() => {
    setfirst(false);
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header Projectdata={CurrentProject}></Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
