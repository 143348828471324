/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

import React, { useState, useEffect } from "react";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/optimization/components/Header";
import PlatformSettings from "layouts/optimization/components/PlatformSettings";

// Images

function Overview() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
        const postData = new URLSearchParams();
        const hash = window.location.hash.substring(1);
        postData.append("id", hash);
        postData.append("table", "Optimize");
        postData.append("idname", "Opt_ID");
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: postData,
        };

        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setProjects(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or processing data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <Loading />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header Diedata={projects[1]}></Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
