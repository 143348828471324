/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
  useRef,
  useLayoutEffect,
  lazy,
  Suspense,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { ProjectContext } from "../../../../context";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { MenuItem, Select, InputLabel, FormControl, TextField } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import MDButton from "components/MDButton";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import "./modalStyles.css";

// Images
const DieShapeForm = lazy(() => import("components/DieShapeForm"));
const CuttingResults = lazy(() => import("layouts/optimization/components/CuttingResults"));
const InventoryList = lazy(() => import("layouts/optimization/components/Inventory"));
const PartList = lazy(() => import("layouts/optimization/components/Parts"));
function Header({ Diedata }) {
  const wrapperRef = useRef(null);
  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [RecordID, setRecordID] = useState("");
  const [DieName, setDieName] = useState("");
  const [FormDate, setFormDate] = useState("");
  const [SolveComp, setSolveComp] = useState(<div></div>);
  const [DieDesc, setDieDesc] = useState(``);
  const [isDirty, setisDirty] = useState(false);
  const [DieProject, setDieProject] = useState("");
  const [DieWeight, setDieWeight] = useState(""); //
  const [Author, setAuthor] = useState("");
  const [AuthorName, setAuthorName] = useState(""); //
  const [results, setresults] = useState('{"Results": ""}'); //
  const [source, setsource] = useState(""); //
  const [Parts, setPartsList] = useState({});
  const [StockLength, setStockLength] = useState(0);
  const [StockWeight, setStockWeight] = useState(0); //
  const [OverMeasure, setOverMeasure] = useState(0); //
  const [OptLength, setOptLength] = useState(0); //
  const [usefulScrap, setusefulScrap] = useState(0); //
  const [Iterations, setIterations] = useState(0); //
  const [Generations, setGenerations] = useState(0); //
  const [Crossover, setCrossover] = useState(0); //
  const [Mutation, setMutation] = useState(0); //
  const [Randomness, setRandomness] = useState(0); //
  const [SawKerf, setSawKerf] = useState(0); //
  const [MaterialFinish, setMaterialFinish] = useState(0); //
  const [FabOrder, setFabOrder] = useState(0); //
  const filter = createFilterOptions();
  const [fileData, setFileData] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { projects, Dies, fetchDieUpdate, DieUpdate, Accounts, fetchAccountsUpdate } =
    useContext(ProjectContext);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [OptLockSB, setOptLock] = useState(false);
  const [ModalData, setModalData] = useState();
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openOptLockSB = () => setOptLock(true);
  const closeOptLockSB = () => setOptLock(false);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [value, setValue] = useState(null);
  const [gridRef, setgridRef] = useState({});
  const [resultLock, setresultLock] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Advanced Mode Activated"
      content="The default settings work well for a vast majority of situations, it you are having trouble with optimizing time or low yeild results. Adjusting these values may help."
      dateTime="now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );
  const GetParts = function () {
    return Parts;
  };

  const renderOptLockSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Cutlist Locked"
      content="Clear optimization to edit locked values."
      dateTime="now"
      open={OptLockSB}
      onClose={closeOptLockSB}
      close={closeOptLockSB}
      bgWhite
    />
  );
  const syncDesc = async () => {
    const newEntry = Object.values(Dies).find((die) => die.DieNum === DieName);
    const newDesc = newEntry ? newEntry.Application : DieDesc;
    setDieDesc(newDesc.replace(/\"/g, '"'));
  };
  const handleClearOpt = (event) => {
    event.preventDefault();
    setresults('{"Results": ""}');
    if (tabValue == 3) {
      setTabValue(1);
    }
    //change tab if tab is cutting
  };
  useEffect(() => {
    setresultLock(results != '{"Results": ""}');
  }, [results]);
  const handleSave = (event) => {
    event.preventDefault();
    function objectToFormData(obj, formData, parentKey = "") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const prefixedKey = parentKey ? `${parentKey}[${key}]` : key;

          if (value instanceof File) {
            formData.append(prefixedKey, value);
          } else if (value instanceof Object) {
            objectToFormData(value, formData, prefixedKey);
          } else {
            formData.append(prefixedKey, value);
          }
        }
      }
    }

    const SaveData = async () => {
      const formDataObject = {
        data: {
          date: FormDate,
          job: DieProject,
          FPID: "",
          diename: DieName,
          diedesc: DieDesc,
          finish: MaterialFinish,
          id: RecordID,
          _MaterialSource: `{ "Order": "${FabOrder}", "Available": "", "Mat_Comment": "" }`,
          Settings: [
            `${SawKerf}`,
            `${StockLength}`,
            `${OverMeasure}`,
            `${usefulScrap}`,
            `${Iterations}`,
            `${Generations}`,
            `${Crossover}`,
            `${Mutation}`,
            `${Randomness}`,
          ],
          PartList: Parts.PartList,
          Results: results,
          DieWeight: DieWeight,
        },
        action: "Update",
      };
      const url = `https://app.waltekltd.org/Forms/Update_Opt.php`;
      // Create a new FormData object
      const formData = new FormData();
      // Append other data to the FormData object
      objectToFormData(formDataObject, formData);

      const options = {
        method: "POST",
        body: formData, // Use FormData instead of URLSearchParams
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const dataret = await response.json();

        if (dataret.Result == "Success") {
          /// console.log(dataret);
          setisDirty(false);
          //success
          //copy data to optimize form
          //setDieName(data.DieName);
          //setDieDesc(data.DieApplication);
          // setDieWeight(data.DieWeight);
          //setModalData({});
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    SaveData(); //send for insert

    handleClose();
  };
  useEffect(() => {
    fetchDieUpdate(DieUpdate); //need to set new dieupdate
    var setting = JSON.parse(Diedata.Opt_Settings);

    setRecordID(Diedata.Opt_ID);
    setSawKerf(setting.Settings[0]);
    setStockLength(setting.Settings[1]);
    setOverMeasure(setting.Settings[2]);
    setusefulScrap(setting.Settings[3]);
    setIterations(setting.Settings[4]);
    setGenerations(setting.Settings[5]);
    setCrossover(setting.Settings[6]);
    setRandomness(setting.Settings[7]);
    setMutation(setting.Settings[8]);
    setDieName(Diedata.Opt_Die);
    setValue(Diedata.Opt_Die);
    setDieDesc(Diedata.Opt_DieDesc.replace(/\"/g, '"'));
    setPartsList(JSON.parse(Diedata.Opt_Part_List));
    setDieProject(Diedata.Opt_Job);
    setOptLength(parseFloat(setting.Settings[1]) - parseFloat(setting.Settings[2]));
    setDieWeight(Diedata.DieWeightFt);
    setAuthor(Diedata.UserID);
    setresults(Diedata.Results);
    setsource(JSON.parse(Diedata.Material_Source));
    setMaterialFinish(Diedata.Opt_Finish);
    setFabOrder(Diedata.Opt_FP_ID);
    setFormDate(Diedata.Opt_Date);
    setIsAdvancedOpen(false);
    if (Diedata.Results != '{"Results": ""}') {
      setresultLock(true);
    }
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
     The event listener that"s calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => {
      // Cleanup code here (if needed)
      window.removeEventListener("resize", handleTabsOrientation);
    };
  }, []);

  const toggleAdvanced = () => {
    setIsAdvancedOpen((prev) => !prev);
    setWarningSB(!isAdvancedOpen);
  };
  const csvToArr = (stringVal, splitter) => {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.replace(/\r/g, "").split(splitter));

    return rest;
  };

  const updateparts = (newValue) => {
    var temp = csvToArr(newValue, ",");
    var newval = { PartList: temp };
    setPartsList(newval);
  };

  const convertToMixedFraction = (value) => {
    if (typeof value === "string") {
      var [wholeStr, fractionStr] = "";
      // Check if the input string contains a space character to determine if it's a mixed fraction
      if (value.includes("/")) {
        if (value.split(" ").length <= 1) {
          wholeStr = 0;
          fractionStr = value;
        } else {
          [wholeStr, fractionStr] = value.split(" ");
        }
        const wholeNumber = parseInt(wholeStr, 10);
        const [numeratorStr, denominatorStr] = fractionStr.split("/");
        const numerator = parseInt(numeratorStr, 10);
        const denominator = parseInt(denominatorStr, 10);
        // Convert the mixed fraction to a decimal value
        return wholeNumber + " " + numerator + "/" + denominator;
      } else {
        // If the input string does not contain a space character, attempt to parse it as a float
        const floatValue = parseFloat(value);
        if (!isNaN(floatValue)) {
          // If parsing is successful, convert the float value to a mixed fraction if FractionView is on

          const wholeNumber = Math.floor(floatValue);
          const fraction = floatValue - wholeNumber;
          if (fraction === 0) {
            return `${wholeNumber}`;
          }
          const precision = 1e6;
          let numerator = fraction * precision;
          let denominator = precision;
          let gcd = function gcd(a, b) {
            return b ? gcd(b, a % b) : a;
          };
          gcd = gcd(numerator, denominator);
          numerator /= gcd;
          denominator /= gcd;
          return `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`;
        }
      }
    } else if (typeof value === "number") {
      // If the input value is a number, convert it to a mixed fraction if FractionView is on

      const wholeNumber = Math.floor(value);
      const fraction = value - wholeNumber;
      if (fraction === 0) {
        return `${wholeNumber}`;
      }
      const precision = 1e6;
      let numerator = fraction * precision;
      let denominator = precision;
      let gcd = function gcd(a, b) {
        return b ? gcd(b, a % b) : a;
      };
      gcd = gcd(numerator, denominator);
      numerator /= gcd;
      denominator /= gcd;
      return `${wholeNumber} ${Math.floor(numerator)}/${Math.floor(denominator)}`;
    }

    // Return an empty string if the input value cannot be converted
    return "";
  };
  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (isAdvancedOpen) {
        wrapper.classList.add("show");
      } else {
        wrapper.classList.remove("show");
      }
    }
  }, [isAdvancedOpen]);
  const handleDirtyChange = () => {
    setisDirty(true);
  };
  useEffect(() => {
    setisDirty(true);
  }, [DieName]);
  useEffect(() => {
    const newEntry = Object.values(Dies).find((die) => die.DieNum === DieName);
    const newWeight = newEntry ? newEntry.Est_Weight : 0;
    //const newDesc = newEntry ? newEntry.Application : DieDesc;
    if (Diedata.Opt_DieDesc != "") {
      setDieDesc(Diedata.Opt_DieDesc.replace(/\"/g, '"'));
    }
    setDieWeight(newWeight);
  }, [DieName, Dies]);

  useEffect(() => {
    setStockWeight((DieWeight * (StockLength / 12)).toFixed(2) + " lbs");
  }, [DieWeight, StockLength]);
  useEffect(() => {
    ////change options for fabrication orders
    const newWeightEntry = Object.values(Dies).find((die) => die.DieNum === DieName);
    const newWeight = newWeightEntry ? newWeightEntry.Est_Weight : 0;
    setDieWeight(newWeight);
  }, [DieProject]);
  useEffect(() => {
    //clear results and set value of cutting stock length
    setOptLength(StockLength - OverMeasure);
  }, [StockLength, OverMeasure]);

  const ModalSubmit = (data) => {
    setModalData(data);
  };
  const InfoTooltip = ({ title }) => {
    return (
      <Tooltip
        title={title} // Adjust the placement to the right end
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  };
  InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
  };

  const Joboptions = Object.entries(projects)
    .map((project) => ({
      key: project[1].ID,
      label: project[1].JobName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const Dieoptions = Object.entries(Dies)
    .map((die) => ({
      key: die[1].ID,
      title: die[1].ID,
      label: die[1].DieNum,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  var AuthorNamehelp = Object.entries(Accounts).find((entry) => entry[1].ID === Diedata.UserID)?.[1]
    ?.EmployeeU; // Find the entry where the EmployeeU matches Author
  var tabinsert = 0;
  if (tabValue == 3 && results == '{"Results": ""}') {
    tabinsert = 1;
  } else {
    tabinsert = tabValue;
  }
  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <FormControl sx={{ minWidth: "100%" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      disableClearable
                      options={Joboptions}
                      value={Joboptions.find((option) => option.key === DieProject) || "7"}
                      onChange={(event, newValue) => {
                        setDieProject(newValue.key);
                        handleDirtyChange();
                      }}
                      renderInput={(params) => <TextField {...params} label="Project" />}
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={DieWeight || 0}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    label="Die Weight Per Foot"
                    onChange={(event) => {
                      setDieWeight(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={StockWeight || 0}
                    type="text"
                    label="Stock Weight"
                    fullWidth
                    disabled
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={MaterialFinish || ""}
                    type="text"
                    label="Material Finish"
                    onChange={(event) => {
                      setMaterialFinish(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={AuthorNamehelp || ""}
                    type="text"
                    label="Author"
                    fullWidth
                    readOnly
                    InputLabelProps={{ shrink: true }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Fabrication Order**</InputLabel>
                    <Select
                      fullWidth
                      sx={{ minHeight: "2.5rem" }}
                      title="Fabrication Order"
                      label="Fabrication Order"
                      value={FabOrder || "0"}
                    >
                      <MenuItem value="0">Square</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Material Settings
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={() => {
                    if (resultLock) {
                      handleShake();
                    }
                  }}
                  disabled="disabled"
                >
                  <MDInput
                    fontWeight="medium"
                    value={StockLength || "144"}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="Total Order Length of material." />
                        {"Stock Order Length"}
                      </div>
                    }
                    onChange={(event) => {
                      setStockLength(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                    autoFocus={!resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={OverMeasure || 0}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="The total length of material lost removing unusable ends for stock." />
                        {"Factory End Removal"}
                      </div>
                    }
                    onChange={(event) => {
                      setOverMeasure(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={OptLength || "0"}
                    type="text"
                    disabled
                    label={
                      <div>
                        <InfoTooltip title="Length of optimizable stock." />
                        {"Cutting Stock Length"}
                      </div>
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Optimize Settings{" "}
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={SawKerf || "0"}
                    type="text"
                    label={
                      <div>
                        <InfoTooltip title="Saw Kerf is the amount of material lost each time a cut is made." />
                        {"Saw-Kerf"}
                      </div>
                    }
                    onChange={(event) => {
                      setSawKerf(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    className="BottomTrayButton"
                    onClick={toggleAdvanced}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{isAdvancedOpen ? "remove" : "add"}</Icon>
                    &nbsp;{isAdvancedOpen ? "Hide Advanced" : "Show Advanced"}
                  </MDButton>
                </MDBox>
                <MDBox className="advancedON" ref={wrapperRef}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={usefulScrap || ".1875"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The minimum leftover stock length that the optimizer will attempt to use in cutting. This should never be set above the shortest part" />
                          {"Useful Scrap"}
                        </div>
                      }
                      onChange={(event) => {
                        setusefulScrap(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Iterations || "100"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Refers to the process of iterating through successive generations of the genetic algorithm" />
                          {"Iterations"}
                        </div>
                      }
                      onChange={(event) => {
                        setIterations(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Generations || "100"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Pertains to the creation of a new randomly ordered population of parts" />
                          {"Generation Size"}
                        </div>
                      }
                      onChange={(event) => {
                        setGenerations(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Crossover || "0.8"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Amount of genes passing from parent to child per generation 0 being none and 1 being All." />
                          {"CrossOver"}
                        </div>
                      }
                      onChange={(event) => {
                        setCrossover(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Mutation || "0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The probability of genes of the new generation mutating." />
                          {"Mutation"}
                        </div>
                      }
                      onChange={(event) => {
                        setMutation(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={Randomness || "0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="High values favor higher genetic randomness, while vaules closer to 0 favor using existing genetic Material." />
                          {"Randomness"}
                        </div>
                      }
                      onChange={(event) => {
                        setRandomness(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                </MDBox>
                {renderWarningSB}
              </Grid>
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <PartList
              parts={Parts}
              updateparts={updateparts}
              dirty={handleDirtyChange}
              handleShake={handleShake}
              FormData={{
                Die: DieName,
                Project: Joboptions.find((option) => option.key === DieProject),
              }}
              resultLock={resultLock}
            />
          </div>
        );
        break;
      case 2:
        content = <InventoryList />;
        break;
      case 3:
        content = (
          <CuttingResults
            dirty={handleDirtyChange}
            FormData={{
              Die: DieName,
              Project: Joboptions.find((option) => option.key === DieProject),
              StockLength: StockLength,
              OverMeasure: OverMeasure,
              StockWeight: StockWeight,
              DieWeight: DieWeight,
              MaterialFinish: MaterialFinish,
              DieDesc: DieDesc,
              FormDate: FormDate,
              Parts: Parts.PartList,
            }}
            Results={results}
          />
        );
        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };

  const handletest = (data) => {
    var test = data.apiRef.current || false;
    if (test) {
      setgridRef(data.apiRef.current);
    }
  };

  const handleDragEnter = () => {
    setIsDraggingOver(true);
  };

  // Define a function to update the state if needed
  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const InsertData = async (data) => {
      const url = `https://app.waltekltd.org/Forms/Update_Die.php`;
      // Create a new FormData object
      const formData = new FormData();

      // Append other data to the FormData object
      formData.append("Die_App", data.DieApplication);
      formData.append("Die_Number", data.DieName);
      formData.append("DiePDFtxt", "");
      formData.append("DieDWGtxt", "");
      formData.append("DiePNGtxt", "");
      formData.append("EstWeight", data.DieWeight);
      formData.append("DiePNGFile", data.fileData);
      formData.append("Est_Peri", "0");
      formData.append("Circle_Size", "0");
      formData.append("Die_Type", data.DieType);
      formData.append("Die_Vendor", data.DieVendor);
      formData.append("DieActive", "1");
      formData.append("action", "InsertNew");

      const options = {
        method: "POST",
        body: formData, // Use FormData instead of URLSearchParams
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const dataret = await response.json();

        if (dataret.Result == "Success") {
          //success
          //copy data to optimize form
          setDieName(data.DieName);
          setDieDesc(data.DieApplication.replace(/\"/g, '"'));
          setDieWeight(data.DieWeight);
          setModalData({});
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    InsertData(ModalData); //send for insert

    handleClose();
  };
  const Optimize = (event) => {
    let count1 = 0;
    let temppart = JSON.parse(JSON.stringify(Parts.PartList));
    let remainingParts = [...Parts.PartList]; // Copy the array to avoid mutating the original
    let StockArray = [{ StockNumber: 1, RemainingLength: 288, PartCut: {} }]; // Initialize an array to hold stock lengths
    var TotalMaterialParts = 0;
    remainingParts.sort((a, b) => parseFloat(b[2]) - parseFloat(a[2])); // Sort parts by length (longest first)

    for (let index = 0; index < remainingParts.length; index++) {
      let partLength = parseFloat(remainingParts[index][2]);
      var partQty = remainingParts[index][1];
      if (!isNaN(remainingParts[index][1])) {
        partQty = parseInt(remainingParts[index][1]);
      }

      remainingParts[index][1] = partQty;
      remainingParts[index][2] = partLength;
      TotalMaterialParts += partQty * partLength;
      switch (remainingParts[index][3]) {
        case "Single Miter":
          partLength += 1;
          break;
        case "Double Miter":
          partLength += 2;
          break;
        default:
          break;
      }

      for (let P = partQty; P > 0; P--) {
        for (let i = 0; i < StockArray.length; i++) {
          if (StockArray[i].RemainingLength >= partLength) {
            StockArray[i].RemainingLength -= partLength + parseFloat(SawKerf); // Reduce the remaining length of the stock
            if (!StockArray[i].PartCut.hasOwnProperty(remainingParts[index][0])) {
              StockArray[i].PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ];
              count1++;
              // Add part to PartCut object if not already present
            } else {
              StockArray[i].PartCut[remainingParts[index][0]][1] += 1; // Increment quantity if part already exists in PartCut object
              count1++;
            }

            break;
          } else {
            if (i + 1 == StockArray.length) {
              let newStock = {
                StockNumber: StockArray.length + 1,
                RemainingLength: StockLength - (partLength + parseFloat(SawKerf)),
                PartCut: {},
              };
              newStock.PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ]; // Add part to PartCut object
              count1++;
              StockArray.push(newStock);
              break;
            }
          }
        }
      }
    }

    Parts.PartList = temppart;
    const output = {
      Stock: StockLength,
      Sticks: StockArray.length,
      Results: [],
      OptYield: 0, // You need to define the calculateOptYield function
    };
    //yield will equal totalpartslength /(#stocks * length)
    StockArray.forEach((stock, index) => {
      let pickuplength = parseFloat(StockLength); //get sum of all previous parts in stock
      Object.keys(stock.PartCut).forEach((part) => {
        const partDetails = stock.PartCut[part];
        const resultItem = {
          qty: partDetails[1],
          part: partDetails[0],
          stock: `'S${index + 1}'`,
          cutLength: convertToMixedFraction(partDetails[2]) + '"',
          pickupStock: convertToMixedFraction(pickuplength) + '"',
          specialAction: partDetails[3], // You may need to modify this based on your requirements
          remainingStock:
            convertToMixedFraction(
              Math.max(pickuplength - partDetails[1] * (partDetails[2] + parseFloat(SawKerf)), 0)
            ) + '"',
          // You need to define the calculateRemainingStock function
        };
        pickuplength -=
          (parseFloat(partDetails[2]) + parseFloat(SawKerf)) * parseFloat(partDetails[1]);
        output.Results.push(resultItem);
      });
    });

    output.OptYield = TotalMaterialParts / (parseFloat(StockLength) * output.Sticks);
    setresults(JSON.stringify(output));
  };
  const OptColorMode = resultLock ? "#ffbfbf" : "#b2b2b2";
  const handleShake = () => {
    setIsShaking(true);
    setOptLock(true);
    // Remove the animation after it's completed
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  useLayoutEffect(() => {
    setisDirty(false);

    return () => {
      // Clean-up code (if needed)
    };
  }, []);
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={`https://waltekltd.org/File/Dies/PNG/${DieName}.png`}
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={handleClick}
              onMouseLeave={handleblur}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                disableClearable
                freeSolo
                options={Dieoptions}
                value={DieName || "New Shape"}
                onChange={(event, newValue) => {
                  //console.log(newValue);
                  handleDirtyChange();
                  if (typeof newValue === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      toggleOpen(true);
                      const vat = newValue.label.replace("+Add ", "");
                      setDialogValue({
                        title: vat,
                        year: "",
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      title: newValue.inputValue,
                      year: "",
                    });
                  } else {
                    setDieName(newValue.label);
                  }
                }}
                getOptionLabel={(option) => {
                  // e.g. value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.label;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const existingOption = options.find(
                    (option) => option.label === params.inputValue
                  );

                  if (params.inputValue && !existingOption) {
                    filtered.push({
                      id: params.inputValue,
                      label: `+Add ${params.inputValue}`,
                      key: params.inputValue,
                    });
                  }

                  return filtered;
                }}
                renderOption={(optionProps, option) => (
                  <li
                    key={option.key}
                    className={optionProps.className}
                    onClick={(event) => {
                      ///use key instead to find if
                      const selectedOption = Dieoptions.find((die) => die.key === option.key);
                      if (selectedOption) {
                        setDieName(option.label);
                      } else {
                        toggleOpen(true);

                        var remov = option.label.replace("+Add ", "");
                        setDialogValue({
                          title: remov,
                          year: "",
                        });
                      }
                    }}
                  >
                    {option.label}
                  </li>
                )}
                selectOnFocus
                renderInput={(params) => <TextField {...params} label="Part Number" />}
              />
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <form onSubmit={handleSubmit}>
                  <DialogTitle>Add a new Material Shape</DialogTitle>
                  <DialogContent>
                    <DialogContentText></DialogContentText>
                    <DieShapeForm SubmitForm={ModalSubmit} Diedata={dialogValue.title} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                  </DialogActions>
                </form>
              </Dialog>
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={DieDesc}
                  type="text"
                  label="Part Description"
                  onChange={(event) => {
                    setDieDesc(event.target.value);
                    handleDirtyChange();
                  }}
                  sx={{ mt: 2 }}
                />
                <MDButton
                  variant="gradient"
                  color="secondary"
                  title="Update Part Description From Material Library"
                  onClick={syncDesc}
                  sx={{ mt: "16px" }}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    sync
                  </Icon>
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Material"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Parts"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />
                <Tab
                  label="Inventory"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      inventory
                    </Icon>
                  }
                />
                <Tab
                  label={resultLock ? "Cutting" : "Optimize"}
                  title={resultLock ? "" : "Must Optimize First"}
                  className={resultLock ? "OptimizeMode" : ""}
                  sx={{ color: { OptColorMode } }}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      content_cut
                    </Icon>
                  }
                  onClick={(event) => {
                    if (!resultLock) {
                      event.preventDefault();
                      Optimize();
                    }
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
          {resultLock && renderOptLockSB}

          <Grid item>
            <MDButton variant="gradient" color="info" onClick={handleSave}>
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
          </Grid>

          {resultLock && (
            <Grid item>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  transform: isShaking ? "translateY(-5px)" : "none",
                  transition: isShaking ? "transform 0.25s ease-in-out" : "none",
                }}
                onClick={handleClearOpt}
              >
                <Icon sx={{ fontWeight: "bold" }}>lockopen</Icon>
                Clear Optimization / Unlock
              </MDButton>
            </Grid>
          )}
        </Grid>
        {children({ tabValue })}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  Diedata: {},
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  Diedata: PropTypes.object,
};
//// linear cutting below

export default Header;
