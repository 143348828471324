/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import Info from "@mui/icons-material/Info";
import { Color } from "chroma-js";
// @mui material components
export default async function useData({ openModal, closeModal }) {
  const Project = ({ image, name, openModal }) => {
    const ImageRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    // Function to handle mouse enter event

    useEffect(() => {
      const wrapper = ImageRef.current;
      const rect =
        wrapper.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();
      const scaleFactor = Math.max(rect.width, rect.height) / 100;
      if (!isClicked) {
        wrapper.style.zIndex = "99";
        wrapper.style.transform = `scale(1)`;
      } else if (wrapper.textContent != "p" && isClicked) {
        wrapper.style.zIndex = "9999999";
        wrapper.style.backgroundColor = "white";
        wrapper.style.transform = `scale(${scaleFactor})`;
      }
    }, [isClicked]);

    // Function to handle mouse leave event

    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar
          src={image}
          name={name}
          size="sm"
          ref={ImageRef}
          variant="rounded"
          onClick={() => {
            openModal(image);
          }}
          style={{
            transition: "transform 0.3s ease",
            cursor: "pointer",
            transformOrigin: "left",
            border: "",
            borderRadius: "0.25rem",
          }}
        />
        <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );
  };

  const fetchData = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();

    postData.append("id", "1");
    postData.append("table", "Inventory");
    postData.append("idname", "");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      var rows = [
        {
          id: "e",
          key: "OptR",
          Blank: "No Results",
        },
      ];
      var columns = [
        {
          field: "Blank",
          headerName: "No Results",
        },
      ];
      if (!jsonData.Results) {
        rows = Array.from(Object.entries(jsonData)).map((item) => ({
          Name: item[1].Name,
          Description: item[1].Description,
          Image: item[1].Image,
          DateAdded: item[1].DateAdded,
          Last_Updated: item[1].Last_Updated,
          status: item[1].Status == 1 ? "Active" : "Discontinued",
          Cost: `$${item[1].Cost}`,
          id: item[1].ID,
          id2: item[1].ID,
          key: "InvR" + item[1].ID,
        }));

        columns = [
          {
            field: "id",
            headerName: "Open/Edit",
            flex: "auto",
            renderCell: (params) => {
              const rowElement = params.api.getRowElement(params.id);
              if (rowElement) {
                rowElement.firstChild.style.justifyContent = "center";
              }

              return (
                <MDTypography
                  component="a"
                  href={`inventory/Item-Details#${params.row.id}`}
                  color="white"
                >
                  <MDBadge
                    badgeContent={"Open"}
                    size="sm"
                    container
                    circular
                    color={params.row.status != "Active" ? "error" : "success"}
                  />
                </MDTypography>
              );
            },
          },
          {
            field: "Name",
            headerName: "Shape Name",
            flex: 1,
            renderCell: (params) => {
              const imgSrc = params.row.Image;
              return (
                <Project image={imgSrc} name={params.row.Name} openModal={openModal}></Project>
              );
            },
          },
          {
            field: "id2",
            headerName: "SKU",
            flex: 1,
          },
          {
            field: "Description",
            headerName: "Description",
            flex: 1,
          },
          {
            field: "DateAdded",
            headerName: "Date Added",
            flex: 1,
          },
          {
            field: "Last_Updated",
            headerName: "Last Updated",
            align: "left",
            headerAlign: "left",
          },
          {
            field: "Cost",
            headerName: "Cost",
            align: "left",
            headerAlign: "left",
          },
          {
            field: "status",
            headerName: "Status",
            flex: 0.5,
          },
        ];
      }
      return { columns, rows };
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return {
        columns: [{ Header: "Alert", accessor: "Alert", align: "center", color: "red" }],
        rows: [
          {
            id: "Alert1",
            key: "Alert12",
            Alert: (
              <MDTypography component="a" color="white">
                <MDBadge
                  badgeContent="Not CutLists Yet"
                  title="Not CutLists Yet"
                  size="lg"
                  container
                  circular
                  color={"error"}
                />
              </MDTypography>
            ),
          },
        ],
      }; // Return empty data in case of error
    }
  };

  return await fetchData();
}
useData.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
};
