import React, { useState, useEffect, useContext } from "react";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDTypography from "components/MDTypography";
import { ProjectContext } from "../../context";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ItemData from "layouts/ItemDetails/data/ItemData";
// Overview page components
import Header from "layouts/ItemDetails/components/Header";

// Images

function Overview() {
  const [Data, setItemData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hash, setHash] = useState(window.location.hash.substring(1));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ItemData2 = await ItemData(hash);
        setItemData(ItemData2 || "Empty");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [hash]);

  if (loading) {
    return <Loading />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header Itemdata={Data}></Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
