/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();
export const ProjectContext = createContext();
export const AuthContext = createContext();
// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    // Check if user is already logged in on page load
    const storedAuthState = localStorage.getItem("isLoggedIn");
    if (storedAuthState === "true") {
      setIsLoggedIn(true);
    }
    /*
    const eventSource = new EventSource("http://localhost:3001/events ");

    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      console.log(eventData);
      console.log("Timestamp:", eventData.timestamp);
    };

    eventSource.onerror = (error) => {
      console.error("Error:", error);
    };*/
  }, []);

  const login = (userEmail, Username, UserId) => {
    // Perform login logic
    // Assuming login is successful, update isLoggedIn state and store it
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("UserName", userEmail);
    localStorage.setItem("User_Name", Username);
    localStorage.setItem("User_ID", UserId);
  };

  const logout = () => {
    // Perform logout logic
    // Clear isLoggedIn state and storage
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    navigate("/authentication/sign-in");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is provided and of type node
};
export const ProjectProvider = ({ children }) => {
  // State to hold the project list
  const [projects, setProjects] = useState([]);
  const [Dies, setDies] = useState([]);
  const [DieUpdate, setDieTableUpdate] = useState("");
  const [Vendors, setVendors] = useState([]);
  const [Accounts, setAccounts] = useState([]);
  const [AccountsUpdate, setAccountsUpdate] = useState("");

  const fetchDieUpdate = async (olddate) => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_secure.php`;
    const postData = new URLSearchParams();
    postData.append("SQL", "SELECT * FROM `Settings` WHERE ID=1");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data[1].DieList_Updated !== null) {
        if (olddate === "") {
          const newtime = data[1].DieList_Updated;
          setDieTableUpdate(newtime);
          console.log(["first Get", data[1].DieList_Updated, olddate]);
          fetchDies();
        } else if (data[1].DieList_Updated !== olddate) {
          console.log(["update Get", data[1].DieList_Updated, olddate]);
          setDieTableUpdate(data[1].DieList_Updated);
          fetchDies();
        } else {
          console.log("no Get");
        }
      } else {
        console.log(["weird die fetch error", data[1].DieList_Updated, olddate]);
      }

      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  const fetchAccountsUpdate = async (olddate) => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_secure.php`;
    const postData = new URLSearchParams();
    postData.append("SQL", "SELECT * FROM `Settings` WHERE ID=1");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      if (data[1].DieList_Updated !== null) {
        if (olddate === "") {
          const newtime = data[1].DieList_Updated;
          setAccountsUpdate(newtime);
          console.log(["first Get", data[1].DieList_Updated, olddate]);
          fetchDies();
        } else if (data[1].DieList_Updated !== olddate) {
          console.log(["update Get", data[1].DieList_Updated, olddate]);
          setAccountsUpdate(data[1].DieList_Updated);
          fetchDies();
        } else {
          console.log("no Get");
        }
      }

      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  // Function to fetch project list from the SQL database
  const fetchProjects = async () => {
    // Fetch projects from the SQL database
    const fetchedProjects = await fetchProjectsFromDatabase();
    // Update the project list in the context
    setProjects(fetchedProjects);
  };
  const fetchDies = async () => {
    // Fetch projects from the SQL database
    const fetchedDies = await fetchDiesFromDatabase();
    // Update the project list in the context
    setDies(fetchedDies);
  };
  const fetchVendors = async () => {
    // Fetch projects from the SQL database
    const fetchedVendors = await fetchVendorsFromDatabase();
    // Update the project list in the context
    setVendors(fetchedVendors);
  };
  const fetchAccounts = async () => {
    // Fetch projects from the SQL database
    const fetchedAccount = await fetchAccountsFromDatabase();
    // Update the project list in the context
    setAccounts(fetchedAccount);
  };

  // Fetch projects on component mount
  useEffect(() => {
    fetchProjects();
    fetchVendors();
    fetchAccounts();
  }, []); // Empty dependency array ensures it runs only once on mount
  const fetchVendorsFromDatabase = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();
    postData.append("id", "1");
    postData.append("table", "VendorList");
    postData.append("idname", "");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  // Function to fetch projects from the SQL database
  const fetchProjectsFromDatabase = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();
    postData.append("id", "1");
    postData.append("table", "Job_List");
    postData.append("idname", "");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  const fetchAccountsFromDatabase = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();
    postData.append("id", "1");
    postData.append("table", "employeerecords");
    postData.append("idname", "");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  const fetchDiesFromDatabase = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();
    postData.append("id", "1");
    postData.append("table", "DieList");
    postData.append("idname", "");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };

  const formatCurrency = (value) => {
    const sanitizedValue = value.replace(/[^0-9.,]/g, "");
    return Number(sanitizedValue).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <ProjectContext.Provider
      value={{
        projects,
        Dies,
        DieUpdate,
        fetchDieUpdate,
        Vendors,
        Accounts,
        fetchAccountsUpdate,
        formatCurrency,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is provided and of type node
};
// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "CURRENT_USER": {
      return { ...state, CurrentUser: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    CurrentUser: "",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setCurrentUser = (dispatch, value) => dispatch({ type: "CURRENT_USER", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setCurrentUser,
  setDirection,
  setLayout,
  setDarkMode,
};
