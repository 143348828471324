/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
  useRef,
  useLayoutEffect,
  lazy,
  Suspense,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { ProjectContext } from "../../../../context";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { MenuItem, Select, InputLabel, FormControl, TextField } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import MDButton from "components/MDButton";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import "./modalStyles.css";

// Images
const DieShapeForm = lazy(() => import("components/DieShapeForm"));
const CuttingResults = lazy(() => import("layouts/Project-Details/components/CuttingResults"));
const InventoryList = lazy(() => import("layouts/Project-Details/components/Inventory"));
const JobStats = lazy(() => import("layouts/Project-Details/components/JobStats"));
function Header({ Projectdata }) {
  const wrapperRef = useRef(null);
  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [RecordID, setRecordID] = useState("");
  const [Abbriviation, setAbbriviation] = useState(Projectdata.Abbriviation || "");
  const [AllowOverhead, setAllowOverhead] = useState(Projectdata.AllowOverhead || 0);
  const [AllowProfit, setAllowProfit] = useState(0);
  const [Customer, setCustomer] = useState(0);
  const [JobName, setJobName] = useState("");
  const [Job_Address1, setJob_Address1] = useState("");
  const [Job_Address2, setJob_Address2] = useState("");
  const [Job_Num, setJob_Num] = useState("");
  const [Useroptions, setUseroptions] = useState([]);
  const [Status, setStatus] = useState("");
  const [isDirty, setisDirty] = useState(false);
  const filter = createFilterOptions();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { projects, Dies, fetchDieUpdate, DieUpdate, Accounts, fetchAccountsUpdate } =
    useContext(ProjectContext);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [OptLockSB, setOptLock] = useState(false);
  const [ModalData, setModalData] = useState();
  const closeWarningSB = () => setWarningSB(false);
  const closeOptLockSB = () => setOptLock(false);
  const [resultLock, setresultLock] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [Waltek_PM, setWaltek_PM] = useState({
    key: localStorage.getItem("User_ID"),
    label: localStorage.getItem("User_Name"),
  });
  useEffect(() => {
    const Useroptions2 = Object.entries(Accounts)
      .map((die) => ({
        key: die[1].ID,
        title: die[1].ID,
        label: die[1].EmployeeU,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setUseroptions(Useroptions2);
    const selectedPM =
      Useroptions2.find((die) => die.key == Projectdata.Waltek_PM) || Useroptions2[0];
    setWaltek_PM(selectedPM);
  }, [Accounts]);
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Advanced Mode Activated"
      content="The default settings work well for a vast majority of situations, it you are having trouble with optimizing time or low yeild results. Adjusting these values may help."
      dateTime="now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );
  const renderOptLockSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Cutlist Locked"
      content="Clear optimization to edit locked values."
      dateTime="now"
      open={OptLockSB}
      onClose={closeOptLockSB}
      close={closeOptLockSB}
      bgWhite
    />
  );

  const handleClearOpt = (event) => {
    event.preventDefault();
    setresults('{"Results": ""}');
    if (tabValue == 3) {
      setTabValue(1);
    }
    //change tab if tab is cutting
  };

  const handleSave = (event) => {
    event.preventDefault();
    function objectToFormData(obj, formData, parentKey = "") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const prefixedKey = parentKey ? `${parentKey}[${key}]` : key;

          if (value instanceof File) {
            formData.append(prefixedKey, value);
          } else if (value instanceof Object) {
            objectToFormData(value, formData, prefixedKey);
          } else {
            formData.append(prefixedKey, value);
          }
        }
      }
    }

    const SaveData = async () => {
      const formDataObject = {
        data: {
          date: FormDate,
          job: DieProject,
          FPID: "",
          finish: MaterialFinish,
          id: RecordID,
          _MaterialSource: `{ "Order": "${FabOrder}", "Available": "", "Mat_Comment": "" }`,
          Settings: [
            `${SawKerf}`,
            `${StockLength}`,
            `${OverMeasure}`,
            `${usefulScrap}`,
            `${Iterations}`,
            `${Generations}`,
            `${Crossover}`,
            `${Mutation}`,
            `${Randomness}`,
          ],
          PartList: Parts.PartList,
          Results: results,
          DieWeight: DieWeight,
        },
        action: "Update",
      };
      const url = `https://app.waltekltd.org/Forms/Update_Opt.php`;
      // Create a new FormData object
      const formData = new FormData();
      // Append other data to the FormData object
      objectToFormData(formDataObject, formData);

      const options = {
        method: "POST",
        body: formData, // Use FormData instead of URLSearchParams
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const dataret = await response.json();

        if (dataret.Result == "Success") {
          setisDirty(false);
          //success
          //copy data to optimize form
          //setDieName(data.DieName);
          //setDieDesc(data.DieApplication);
          // setDieWeight(data.DieWeight);
          //setModalData({});
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    SaveData(); //send for insert

    handleClose();
  };
  useEffect(() => {
    setRecordID(Projectdata.ID);
    setAbbriviation(Projectdata.Abbriviation);
    setAllowOverhead(Projectdata.AllowOverhead);
    setAllowProfit(Projectdata.AllowProfit);
    setCustomer(Projectdata.Customer);
    setJobName(Projectdata.JobName);
    setJob_Address1(Projectdata.Job_Address1);
    setJob_Address2(Projectdata.Job_Address2);
    setJob_Num(Projectdata.Job_Num);
    setStatus(Projectdata.Status);
    setIsAdvancedOpen(false);
    /*
    if (Projectdata.Results != '{"Results": ""}') {
      setresultLock(true);
    }*/
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
     The event listener that"s calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => {
      // Cleanup code here (if needed)
      window.removeEventListener("resize", handleTabsOrientation);
    };
  }, []);

  const toggleAdvanced = () => {
    setIsAdvancedOpen((prev) => !prev);
    setWarningSB(!isAdvancedOpen);
  };
  const csvToArr = (stringVal, splitter) => {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.replace(/\r/g, "").split(splitter));

    return rest;
  };

  const updateparts = (newValue) => {
    var temp = csvToArr(newValue, ",");
    var newval = { PartList: temp };
    setPartsList(newval);
  };

  const handleDirtyChange = () => {
    setisDirty(true);
  };

  const ModalSubmit = (data) => {
    setModalData(data);
  };
  const InfoTooltip = ({ title }) => {
    return (
      <Tooltip
        title={title} // Adjust the placement to the right end
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  };
  InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
  };

  var tabinsert = 0;
  if (tabValue == 3 && results == '{"Results": ""}') {
    tabinsert = 1;
  } else {
    tabinsert = tabValue;
  }
  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                ></MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Job_Address1 || ""}
                    label="Address"
                    onChange={(event) => {
                      setJob_Address1(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Job_Address2 || ""}
                    onChange={(event) => {
                      setJob_Address2(event.target.value);
                      handleDirtyChange();
                    }}
                    type="text"
                    label="City, State Zip"
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Job_Num || ""}
                    onChange={(event) => {
                      setJob_Num(event.target.value);
                      handleDirtyChange();
                    }}
                    type="text"
                    label="Job Number"
                    fullWidth
                  />
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disableClearable
                    fullWidth
                    options={Useroptions}
                    value={Waltek_PM}
                    onChange={(event, newValue) => {
                      handleDirtyChange();
                      setWaltek_PM(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.key == value.key;
                    }}
                    getOptionLabel={(option) => {
                      // e.g. value selected with enter, right from the input
                      if (typeof option === "string" && Useroptions) {
                        const selectedOption = Useroptions.find((die) => die.key === option);
                        if (selectedOption) {
                          return selectedOption.label;
                        }
                      }
                      if (option.inputValue) {
                        return option.label;
                      }
                      return option.label;
                    }}
                    filterOptions={(options, params) => {
                      return filter(options, params);
                    }}
                    renderOption={(optionProps, option) => (
                      <li key={option.key} {...optionProps}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Project Manager" />}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Material Settings
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={() => {
                    if (resultLock) {
                      handleShake();
                    }
                  }}
                  disabled="disabled"
                >
                  <MDInput
                    fontWeight="medium"
                    value={"144"}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="Total Order Length of material." />
                        {"Stock Order Length"}
                      </div>
                    }
                    onChange={(event) => {
                      setStockLength(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                    autoFocus={!resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={0}
                    type="number"
                    label={
                      <div>
                        <InfoTooltip title="The total length of material lost removing unusable ends for stock." />
                        {"Factory End Removal"}
                      </div>
                    }
                    onChange={(event) => {
                      setOverMeasure(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={"0"}
                    type="text"
                    disabled
                    label={
                      <div>
                        <InfoTooltip title="Length of optimizable stock." />
                        {"Cutting Stock Length"}
                      </div>
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Optimize Settings{" "}
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={"0"}
                    type="text"
                    label={
                      <div>
                        <InfoTooltip title="Saw Kerf is the amount of material lost each time a cut is made." />
                        {"Saw-Kerf"}
                      </div>
                    }
                    onChange={(event) => {
                      setSawKerf(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                    disabled={resultLock}
                  />
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    className="BottomTrayButton"
                    onClick={toggleAdvanced}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{isAdvancedOpen ? "remove" : "add"}</Icon>
                    &nbsp;{isAdvancedOpen ? "Hide Advanced" : "Show Advanced"}
                  </MDButton>
                </MDBox>
                <MDBox className="advancedON" ref={wrapperRef}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={".1875"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The minimum leftover stock length that the optimizer will attempt to use in cutting. This should never be set above the shortest part" />
                          {"Useful Scrap"}
                        </div>
                      }
                      onChange={(event) => {
                        setusefulScrap(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={"100"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Refers to the process of iterating through successive generations of the genetic algorithm" />
                          {"Iterations"}
                        </div>
                      }
                      onChange={(event) => {
                        setIterations(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={"100"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Pertains to the creation of a new randomly ordered population of parts" />
                          {"Generation Size"}
                        </div>
                      }
                      onChange={(event) => {
                        setGenerations(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={"0.8"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="Amount of genes passing from parent to child per generation 0 being none and 1 being All." />
                          {"CrossOver"}
                        </div>
                      }
                      onChange={(event) => {
                        setCrossover(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={"0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="The probability of genes of the new generation mutating." />
                          {"Mutation"}
                        </div>
                      }
                      onChange={(event) => {
                        setMutation(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    px={2}
                    onClick={resultLock ? handleShake : () => {}}
                  >
                    <MDInput
                      fontWeight="medium"
                      value={"0.3"}
                      type="text"
                      label={
                        <div>
                          <InfoTooltip title="High values favor higher genetic randomness, while vaules closer to 0 favor using existing genetic Material." />
                          {"Randomness"}
                        </div>
                      }
                      onChange={(event) => {
                        setRandomness(event.target.value);
                        handleDirtyChange();
                      }}
                      fullWidth
                      disabled={resultLock}
                    />
                  </MDBox>
                </MDBox>
                {renderWarningSB}
              </Grid>
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <JobStats JobData={Projectdata} />
          </div>
        );
        break;
      case 2:
        content = <InventoryList />;
        break;
      case 3:
        content = (
          <CuttingResults
            dirty={handleDirtyChange}
            FormData={{
              Project: Joboptions.find((option) => option.key === DieProject),
              StockLength: StockLength,
              OverMeasure: OverMeasure,
              StockWeight: StockWeight,
              DieWeight: DieWeight,
              MaterialFinish: MaterialFinish,
              DieDesc: DieDesc,
              FormDate: FormDate,
              Parts: Parts.PartList,
            }}
            Results={results}
          />
        );
        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };

  const handletest = (data) => {
    var test = data.apiRef.current || false;
    if (test) {
      setgridRef(data.apiRef.current);
    }
  };

  const handleDragEnter = () => {
    setIsDraggingOver(true);
  };

  // Define a function to update the state if needed
  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const InsertData = async (data) => {
      const url = `https://app.waltekltd.org/Forms/Update_Die.php`;
      // Create a new FormData object
      const formData = new FormData();

      // Append other data to the FormData object
      formData.append("Die_App", data.DieApplication);
      formData.append("DiePDFtxt", "");
      formData.append("DieDWGtxt", "");
      formData.append("DiePNGtxt", "");
      formData.append("EstWeight", data.DieWeight);
      formData.append("DiePNGFile", data.fileData);
      formData.append("Est_Peri", "0");
      formData.append("Circle_Size", "0");
      formData.append("Die_Type", data.DieType);
      formData.append("Die_Vendor", data.DieVendor);
      formData.append("DieActive", "1");
      formData.append("action", "InsertNew");

      const options = {
        method: "POST",
        body: formData, // Use FormData instead of URLSearchParams
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const dataret = await response.json();

        if (dataret.Result == "Success") {
          //success
          //copy data to optimize form

          //setDieWeight(data.DieWeight);
          setModalData({});
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    InsertData(ModalData); //send for insert

    handleClose();
  };
  const Optimize = (event) => {
    let count1 = 0;
    let temppart = JSON.parse(JSON.stringify(Parts.PartList));
    let remainingParts = [...Parts.PartList]; // Copy the array to avoid mutating the original
    let StockArray = [{ StockNumber: 1, RemainingLength: 288, PartCut: {} }]; // Initialize an array to hold stock lengths

    remainingParts.sort((a, b) => parseFloat(b[2]) - parseFloat(a[2])); // Sort parts by length (longest first)
    console.log([Parts, StockLength, remainingParts.length]);

    for (let index = 0; index < remainingParts.length; index++) {
      let partLength = parseFloat(remainingParts[index][2]);
      var partQty = remainingParts[index][1];
      if (!isNaN(remainingParts[index][1])) {
        partQty = parseInt(remainingParts[index][1]);
      }

      remainingParts[index][1] = partQty;
      remainingParts[index][2] = partLength;
      switch (remainingParts[index][3]) {
        case "Single Miter":
          partLength += 1;
          break;
        case "Double Miter":
          partLength += 2;
          break;
        default:
          break;
      }

      for (let P = partQty; P > 0; P--) {
        for (let i = 0; i < StockArray.length; i++) {
          if (StockArray[i].RemainingLength >= partLength) {
            StockArray[i].RemainingLength -= partLength + parseFloat(SawKerf); // Reduce the remaining length of the stock
            if (!StockArray[i].PartCut.hasOwnProperty(remainingParts[index][0])) {
              StockArray[i].PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ];
              count1++;
              // Add part to PartCut object if not already present
            } else {
              StockArray[i].PartCut[remainingParts[index][0]][1] += 1; // Increment quantity if part already exists in PartCut object
              count1++;
            }

            break;
          } else {
            if (i + 1 == StockArray.length) {
              let newStock = {
                StockNumber: StockArray.length + 1,
                RemainingLength: StockLength - (partLength + parseFloat(SawKerf)),
                PartCut: {},
              };
              newStock.PartCut[remainingParts[index][0]] = [
                remainingParts[index][0],
                1,
                partLength,
                remainingParts[index][3],
              ]; // Add part to PartCut object
              count1++;
              StockArray.push(newStock);
              break;
            }
          }
        }
      }
    }

    Parts.PartList = temppart;
    const output = {
      Stock: StockLength,
      Sticks: StockArray.length,
      Results: [],
      OptYield: ".97", // You need to define the calculateOptYield function
    };
    StockArray.forEach((stock, index) => {
      let pickuplength = parseFloat(StockLength); //get sum of all previous parts in stock
      Object.keys(stock.PartCut).forEach((part) => {
        const partDetails = stock.PartCut[part];
        const resultItem = {
          qty: partDetails[1],
          part: partDetails[0],
          stock: `'S${index + 1}'`,
          cutLength: convertToMixedFraction(partDetails[2]) + '"',
          pickupStock: convertToMixedFraction(pickuplength) + '"',
          specialAction: partDetails[3], // You may need to modify this based on your requirements
          remainingStock:
            convertToMixedFraction(
              Math.max(pickuplength - partDetails[1] * (partDetails[2] + parseFloat(SawKerf)), 0)
            ) + '"',
          // You need to define the calculateRemainingStock function
        };
        pickuplength -=
          (parseFloat(partDetails[2]) + parseFloat(SawKerf)) * parseFloat(partDetails[1]);
        output.Results.push(resultItem);
      });
    });

    setresults(JSON.stringify(output));
  };
  const OptColorMode = resultLock ? "#ffbfbf" : "#b2b2b2";
  const handleShake = () => {
    setIsShaking(true);
    setOptLock(true);
    // Remove the animation after it's completed
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  useLayoutEffect(() => {
    setisDirty(false);

    return () => {
      // Clean-up code (if needed)
    };
  }, []);
  const createAbbreviation = (input) => {
    // Split the input string into words based on spaces
    const words = input.split(" ");

    // Map each word to its first character, convert it to uppercase, and join them to create the abbreviation
    const abbreviation = words
      .map((word) => {
        // Get the first character of the word
        const firstCharacter = word.charAt(0);

        // Convert the first character to uppercase
        return firstCharacter.toUpperCase();
      })
      .join("");

    return abbreviation;
  };
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={
                `https://ui-avatars.com/api/?background=2f8de9&color=fff&name=` +
                JobName +
                `&rounded=true&bold=true&font-size=0.33&length=4`
              }
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={handleClick}
              onMouseLeave={handleblur}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDInput
                fontWeight="medium"
                value={JobName || ""}
                type="text"
                label="Project Name"
                onChange={(event) => {
                  setJobName(event.target.value);
                  handleDirtyChange();
                }}
                sx={{ mt: 2 }}
              />
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <form onSubmit={handleSubmit}>
                  <DialogTitle>Add a new Material Shape</DialogTitle>
                  <DialogContent>
                    <DialogContentText></DialogContentText>
                    <DieShapeForm SubmitForm={ModalSubmit} Projectdata={dialogValue.title} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                  </DialogActions>
                </form>
              </Dialog>
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={Abbriviation || ""}
                  type="text"
                  label="Abbrivation"
                  onChange={(event) => {
                    //setDieDesc(event.target.value);
                    handleDirtyChange();
                  }}
                  sx={{ mt: 2 }}
                />
                <MDButton
                  variant="gradient"
                  color="secondary"
                  title="Auto Abbreviate"
                  onClick={() => {
                    ///get the first letter and the first character after each space of a varible to create an abrriviation
                    setAbbriviation(createAbbreviation(JobName));
                  }}
                  sx={{ mt: "16px" }}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    sync
                  </Icon>
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="General Info"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Stats"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />
                <Tab
                  label="Extra2"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      inventory
                    </Icon>
                  }
                />
                <Tab
                  label={"Extra3"}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      content_cut
                    </Icon>
                  }
                  onClick={(event) => {
                    if (!resultLock) {
                      event.preventDefault();
                      Optimize();
                    }
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
          {resultLock && renderOptLockSB}

          <Grid item>
            <MDButton variant="gradient" color="info" onClick={handleSave}>
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
          </Grid>

          {resultLock && (
            <Grid item>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  transform: isShaking ? "translateY(-5px)" : "none",
                  transition: isShaking ? "transform 0.25s ease-in-out" : "none",
                }}
                onClick={handleClearOpt}
              >
                <Icon sx={{ fontWeight: "bold" }}>lockopen</Icon>
                Clear Optimization / Unlock
              </MDButton>
            </Grid>
          )}
        </Grid>
        {children({ tabValue })}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  Projectdata: {},
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  Projectdata: PropTypes.object,
};
//// linear cutting below

export default Header;
