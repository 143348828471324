/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
  useRef,
  useLayoutEffect,
  lazy,
  Suspense,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { ProjectContext } from "../../../../context";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBadge from "components/MDBadge";
import URLArray from "components/URLArray";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import { MenuItem, Select, InputLabel, FormControl, TextField, Typography } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import MDButton from "components/MDButton";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import StatBlock from "components/StatBlock";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import "./modalStyles.css";

// Images

const CuttingResults = lazy(() => import("layouts/ItemDetails/components/CuttingResults"));
const HistoryList = lazy(() => import("layouts/ItemDetails/components/History"));
const Stats = lazy(() => import("layouts/ItemDetails/components/Stats"));
function Header({ Itemdata }) {
  const wrapperRef = useRef(null);
  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [isDirty, setisDirty] = useState(false);
  const [ID, setID] = useState(Itemdata[0].ID);
  const [Name, setName] = useState(Itemdata[0].Name);
  const [Description, setDescription] = useState(Itemdata[0].Description);
  const [Image, setImage] = useState(Itemdata[0].Image);
  const [DateAdded, setDateAdded] = useState(Itemdata[0].DateAdded);
  const [Status, setStatus] = useState(Itemdata[0].Status);
  const [Link, setLink] = useState(JSON.parse(Itemdata[0].Link));
  const [Last_Updated, setLast_Updated] = useState(Itemdata[0].Last_Updated);
  const [Cost, setCost] = useState(Itemdata[0].Cost);
  const [Stock, setStock] = useState(Itemdata[0].Stock);

  const { Accounts, formatCurrency } = useContext(ProjectContext);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [OptLockSB, setOptLock] = useState(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeOptLockSB = () => setOptLock(false);
  const [resultLock, setresultLock] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Advanced Mode Activated"
      content="The default settings work well for a vast majority of situations, it you are having trouble with optimizing time or low yeild results. Adjusting these values may help."
      dateTime="now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );
  const CustomPropertiesComponent = () => {
    if (Itemdata[1]) {
      if (!Itemdata[1].error) {
        return <StatBlock data={Itemdata[1]} />;
      } else {
        // Return null if there's an error
        return null;
      }
    }
  };

  const renderOptLockSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Cutlist Locked"
      content="Clear optimization to edit locked values."
      dateTime="now"
      open={OptLockSB}
      onClose={closeOptLockSB}
      close={closeOptLockSB}
      bgWhite
    />
  );

  const handleSave = (event) => {
    event.preventDefault();
    function objectToFormData(obj, formData, parentKey = "") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const prefixedKey = parentKey ? `${parentKey}[${key}]` : key;

          if (value instanceof File) {
            formData.append(prefixedKey, value);
          } else if (value instanceof Object) {
            objectToFormData(value, formData, prefixedKey);
          } else {
            formData.append(prefixedKey, value);
          }
        }
      }
    }

    const SaveData = async () => {
      const formDataObject = {
        data: {
          date: FormDate,
          job: DieProject,
          FPID: "",
          finish: MaterialFinish,
          id: ID,
          _MaterialSource: `{ "Order": "${FabOrder}", "Available": "", "Mat_Comment": "" }`,
          Settings: [
            `${SawKerf}`,
            `${StockLength}`,
            `${OverMeasure}`,
            `${usefulScrap}`,
            `${Iterations}`,
            `${Generations}`,
            `${Crossover}`,
            `${Mutation}`,
            `${Randomness}`,
          ],
          PartList: Parts.PartList,
          Results: results,
          DieWeight: DieWeight,
        },
        action: "Update",
      };
      const url = `https://app.waltekltd.org/Forms/Update_Opt.php`;
      // Create a new FormData object
      const formData = new FormData();
      // Append other data to the FormData object
      objectToFormData(formDataObject, formData);

      const options = {
        method: "POST",
        body: formData, // Use FormData instead of URLSearchParams
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const dataret = await response.json();

        if (dataret.Result == "Success") {
          setisDirty(false);
          //success
          //copy data to optimize form
          //setDieName(data.DieName);
          //setDieDesc(data.DieApplication);
          // setDieWeight(data.DieWeight);
          //setModalData({});
        }
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    SaveData(); //send for insert

    handleClose();
  };
  useEffect(() => {
    setIsAdvancedOpen(false);

    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => {
      // Cleanup code here (if needed)
      window.removeEventListener("resize", handleTabsOrientation);
    };
  }, []);

  const toggleAdvanced = () => {
    setIsAdvancedOpen((prev) => !prev);
    setWarningSB(!isAdvancedOpen);
  };

  const handleDirtyChange = () => {
    setisDirty(true);
  };

  const InfoTooltip = ({ title }) => {
    return (
      <Tooltip
        title={title} // Adjust the placement to the right end
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  };
  InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
  };

  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                ></MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={DateAdded || ""}
                    label="Date Added"
                    type="date"
                    onChange={(event) => {
                      setDateAdded(event.target.value);
                      handleDirtyChange();
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    type="datetime"
                    value={Last_Updated || ""}
                    label="Last Updated On"
                    fullWidth
                    readOnly
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={Cost || ""}
                    onInput={(event) => {
                      setCost(event.target.value);
                      handleDirtyChange();
                    }}
                    onBlur={(event) => {
                      setCost(formatCurrency(event.target.value));
                      handleDirtyChange();
                    }}
                    type="text" // Change type to "text" since it's not a pure number input anymore
                    label="MSRP"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Product Options
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="Product_Status">Product Status</InputLabel>
                    <Select
                      labelId="Product_Status"
                      sx={{
                        height: "3rem",
                        p: 1,
                        lineHeight: 1,
                      }}
                      title="Product Status"
                      label="Product Status"
                      value={Status || "0"}
                      onChange={(event, newValue) => {
                        setStatus(newValue.props.value);
                        handleDirtyChange();
                      }}
                    >
                      <MenuItem value="0">Discontinued</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  Relavent Links
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={() => {
                    handleShake();
                  }}
                  disabled="disabled"
                >
                  <URLArray setLink={setLink} initialUrls={Link}></URLArray>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomPropertiesComponent />
              </Grid>
              {renderWarningSB}
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <Stats JobData={Itemdata[0]} />
          </div>
        );
        break;
      case 2:
        content = <HistoryList />;
        break;
      case 3:
        content = (
          <CuttingResults
            dirty={handleDirtyChange}
            FormData={{
              Project: Joboptions.find((option) => option.key === DieProject),
              StockLength: StockLength,
              OverMeasure: OverMeasure,
              StockWeight: StockWeight,
              DieWeight: DieWeight,
              MaterialFinish: MaterialFinish,
              DieDesc: DieDesc,
              FormDate: FormDate,
              Parts: Parts.PartList,
            }}
            Results={results}
          />
        );
        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };

  const handleShake = () => {
    setIsShaking(true);
    setOptLock(true);
    // Remove the animation after it's completed
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  useLayoutEffect(() => {
    setisDirty(false);

    return () => {
      // Clean-up code (if needed)
    };
  }, []);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={Image}
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={() => {
                handleShake();
              }}
              onMouseLeave={() => {
                handleblur;
              }}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDInput
                fontWeight="medium"
                value={Name || ""}
                type="text"
                label="Project Name"
                onChange={(event) => {
                  setName(event.target.value);
                  handleDirtyChange();
                }}
                sx={{ mt: 2 }}
              />
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={Description || ""}
                  type="text"
                  label="Description"
                  onChange={(event) => {
                    setDescription(event.target.value);
                    handleDirtyChange();
                  }}
                  sx={{ mt: 2 }}
                />
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="General Info"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Stats"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />
                <Tab
                  label="Sales History"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      inventory
                    </Icon>
                  }
                />
                <Tab
                  label={"Store Stock"}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      content_cut
                    </Icon>
                  }
                  onClick={(event) => {}}
                />
              </Tabs>
            </AppBar>
          </Grid>

          {resultLock && renderOptLockSB}

          <Grid item>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                handleSave();
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
          </Grid>

          {resultLock && (
            <Grid item>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  transform: isShaking ? "translateY(-5px)" : "none",
                  transition: isShaking ? "transform 0.25s ease-in-out" : "none",
                }}
                onClick={() => {}}
              >
                <Icon sx={{ fontWeight: "bold" }}>lockopen</Icon>
                Clear Optimization / Unlock
              </MDButton>
            </Grid>
          )}
        </Grid>
        {children({ tabValue })}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  Itemdata: {},
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  Itemdata: PropTypes.array,
};
//// linear cutting below

export default Header;
