/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data() {
  const [rows, setRows] = useState([]);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography
          component="a"
          href={`mailto:${email}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {email}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
      const postData = new URLSearchParams();
      postData.append("id", "1");
      postData.append("table", "employeerecords");
      postData.append("idname", "Active");
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: postData,
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const rowsData = Array.from(Object.entries(data)).map((item) => ({
          author: <Author image={team2} name={item[1].EmployeeU} email={item[1].Email} />,
          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item[1].Active} color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          employed: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {item.ShopAdmin}
            </MDTypography>
          ),
          action: (
            <MDTypography
              component="a"
              href={`#${item[1].ID}`}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              Edit
            </MDTypography>
          ),
        }));
        setRows(rowsData);
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  const columns = [
    { Header: "author", accessor: "author", width: "45%", align: "left" },
    { Header: "function", accessor: "function", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "employed", accessor: "employed", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  return { columns, rows };
}
