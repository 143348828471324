/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import PropTypes from "prop-types";
import DNDData from "./srd_5e_monsters.json";
// @mui material components
export default async function useData(ItemID) {
  const fetchData = async () => {
    const url = `https://app.waltekltd.org/Forms/Get_Data_Multiple.php`;
    const postData = new URLSearchParams();

    postData.append("id", ItemID);
    postData.append("table", "Inventory");
    postData.append("idname", "ID");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const jsonData = await response.json();
      const jsonData2 = DNDData;
      const resultr = jsonData2.find((item) => {
        return item.name == jsonData[1].Name;
      });
      return [jsonData[1], resultr];
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return {
        Result: "No Item Found",
      }; // Return empty data in case of error
    }
  };

  return await fetchData();
}
useData.propTypes = {
  ItemID: PropTypes.string,
};
