import React, { useState, useEffect, useContext } from "react";
import { ProjectContext } from "../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";

const Tables = () => {
  const apiRef = useGridApiRef();
  const { projects, Accounts } = useContext(ProjectContext);
  const [rows, setRows] = useState([]);
  const [PMs, setPMs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const selectedPM = {};
    if (Object.keys(Accounts).length > 0) {
      Object.entries(Accounts).map((project, index) => {
        const { Active, Company, Email, EmployeeU, ID, Initials } = project[1];
        const projectData = {
          Active,
          Company,
          Email,
          EmployeeU,
          Initials,
        };
        selectedPM[ID + ""] = projectData;
      });

      setPMs(selectedPM);
    }
    if (projects && Object.keys(projects).length > 0 && Object.keys(Accounts).length > 0) {
      const initialRows = Object.entries(projects)
        .map((project, index) => {
          const { JobName, Abbriviation, Job_Address2, Waltek_PM, Status, ID } = project[1];
          var EmpName = "Error";

          if (selectedPM[parseInt(Waltek_PM)]) {
            EmpName = selectedPM[parseInt(Waltek_PM)].EmployeeU || "Error";
          }

          return {
            id: ID, // Start ID from 1
            JobName,
            Abbriviation,
            Job_Address2,
            EmpName,
            Status,
          };
        }) // Sort the rows by Status first, then by JobName
        .sort((a, b) => {
          // Compare by Status first
          if (a.Status < b.Status) {
            return -1; // a comes before b
          } else if (a.Status > b.Status) {
            return 1; // a comes after b
          } else {
            // If Status values are equal, compare by JobName
            return a.JobName.localeCompare(b.JobName);
          }
        });

      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [projects, Accounts]);

  const columns = [
    {
      field: "Status",
      headerName: "Open/Edit",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <MDTypography
            component="a"
            href={`Projects/project-Details#${params.row.id}`}
            color="white"
          >
            <MDBadge
              badgeContent={"Open"}
              size="sm"
              container
              circular
              color={params.value == 2 ? "error" : "success"}
            />
          </MDTypography>
        );
      },
    },
    {
      field: "JobName",
      headerName: "Job Name",
      flex: 1,
    },
    {
      field: "Abbriviation",
      headerName: "Abbriviation",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "Job_Address2",
      headerName: "Job Location",
      flex: 0.5,
    },
    {
      field: "EmpName",
      headerName: "Project Manager",
      flex: 0.5,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Project List
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      onClick={() => alert("Add New Project clicked")}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Add New Project
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    moreActionsIcon
                    disableRowSelectionOnClick
                    loading={rows.length === 0}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
